.statusCircle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
}

.success {
  background-color: var(--seadapted);
}

.error {
  background-color: var(--error-color);
}
