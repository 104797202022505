.evalAdvisor {
  width: 70%;
}
.svg {
  cursor: pointer;
}
.modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 450px;
  min-height: 305px;
  padding: 20px 25px 10px;
  overflow-x: hidden;
}

.commonForm {
  margin: 0 auto;
  width: fit-content;
}

.subjectInfo {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-items: center;
  padding: 15px 0;
}
.subjectLabel {
  width: 240px;
}
.subjectLevel {
  justify-self: center;
  min-width: 90px !important;
  width: 90px;
  height: 45px;
}
.recommendation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 18px 0 0;
}

.evalButton {
  align-self: center;
  margin-top: auto;
}
