.notebookSelect {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: inherit;
  gap: 25px;
  padding: 0 25px;
}

.customSelect {
  min-width: 100% !important;
}

.vectorIcon {
  height: 15px;
}
