.blackboard {
  width: 100%;
  height: var(--blackboardCalculatedHeight);
}
.blackboard:focus {
  outline: none;
}

.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  display: flex;
  margin-bottom: 6px;
  position: relative;
}
.innerContainer.dragAndDropArea::after {
  position: absolute;
  border: 5px dashed var(--dadada);
  border-radius: inherit;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: var(--bright);
  display: flex;
  font-weight: 500;
  font-size: var(--font-size-20);
  justify-content: center;
  align-items: center;
  background: var(--purpletronic);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.innerContainer.dropLabel::after {
  content: 'Suelta el archivo';
}
.innerContainer.uploadingLabel::after {
  content: 'Subiendo el archivo...';
}

.notebookArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60%;
  border-radius: 16px 0 0 16px;
  border-right: 0.5px solid var(--text-color-menu-item);
}
.notebookActions {
  display: grid;
  place-items: center;
  width: 17%;
  height: 100%;
  cursor: pointer;
  margin: 0 auto;
}
.notebookChevrons {
  width: 30px;
  height: 30px;
}
.notebookCanvasArea {
  height: inherit;
  width: calc(100% - 35%);
  position: relative;
}

.notebookCanvas {
  background: var(--disabled-color-24);
  box-shadow: 0px 0px 35px 0px rgba(144, 144, 174, 0.2);
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.notebookCanvasDisabled {
  cursor: not-allowed;
}

.canvasArea {
  height: 100%;
  width: 40%;
  position: relative;
}
.canvas {
  height: 100%;
  width: 100%;
  border-radius: 0 16px 16px 0;
}

.notebookSelects {
  display: grid;
  gap: 25px;
  height: fit-content;
  margin: auto 0;
  width: 40%;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  display: flex;
  gap: 10px;
}
.tools .right {
  display: flex;
  gap: 10px;
}
.tools .right .innerRight {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 5px;
  height: 43px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 21.5px;
}
.tools .right .innerRight .icons {
  width: 22px;
  height: 18px;
  margin: 0 8px;
  cursor: pointer;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  padding: 8px 24px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: var(--purpletronic-40);
  opacity: 0.94;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.modalWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 100%;
  background: var(--bright);
  box-shadow: 0px 0px 35px 0px rgba(144, 144, 174, 0.2);
  border-radius: 26px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  isolation: isolate;
}
.modalContent {
  border-radius: 26px 26px 0 0;
  overflow-x: auto;
  overflow-y: auto;
}
.zoomPageCanvas {
  width: 1200px;
  height: 1700px;
}

.zoomPageCanvas.adjusted {
  --page-width: calc(100vw - 100px);
  width: var(--page-width);
  height: calc(var(--page-width) * 1.33);
}

.modalActions {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  border-top: 0.5px solid var(--disabled-color-24);
  border-radius: 0 0 26px 26px;
  padding: 4px;
}

.statusIconDisabled {
  cursor: not-allowed !important;
}
.statusModalContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}
.strong {
  font-weight: bold;
}
