.icon {
  position: fixed;
  top: 0;
  right: 0.25rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.monitorContainer {
  padding: 20px;
  max-width: 800px;
}

.header {
  font-size: var(--font-size-24);
  text-align: center;
  color: var(--dark-grey);
  margin-bottom: 10px;
}

.subHeader {
  font-size: var(--font-size-20);
  color: var(--dark-grey);
  margin-bottom: 10px;
}

.section {
  margin-bottom: 15px;
}
.section > p {
  margin-bottom: 5px;
}

.highlight {
  font-weight: bold;
  color: var(--purpletronic);
  opacity: 0.8;
}

.validSystem {
  text-align: center;
  color: var(--seadapted);
}
.invalidSystem {
  text-align: center;
  color: var(--error-color);
}
