.toolbar {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 5px;
  height: 43px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 21.5px;
}

.iconContainer {
  margin: 0 6px;
  width: 35px;
  height: 18px;
  cursor: pointer;
}
.iconContainer:hover {
  opacity: 1;
}
.unfocused {
  opacity: 0.3;
}

.brushColors {
  position: absolute;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 5px 10px;
  height: 43px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 21.5px;
  gap: 10px;
  left: 50px;
  background: var(--bright);
}

.brushColor {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
}
