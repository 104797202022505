.container {
  position: relative;
  cursor: pointer;
  border-radius: 100%;
}

.reconnecting {
  cursor: not-allowed;
}

.timer {
  position: absolute;
  right: 0;
  z-index: 1;
}
.lightbulbTimer {
  position: absolute;
  left: 30px;
  bottom: 0;
  z-index: 2;
}

.lightbulbContainer {
  position: absolute;
  bottom: 0;
  width: 32px;
  height: 32px;
  z-index: 1;
}
.lightbulbInnerContainer {
  width: 32px;
  height: 32px;
  padding: 6px;
}
.icon {
  width: 32px;
  height: 32px;
}
.wrapperIcon {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.innerBackground {
  display: none;
  position: absolute;
  background-color: var(--parent-color);
  padding: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100%;
  z-index: 0;
}

.container:hover .innerBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.containerChatIcon {
  width: 28px;
  height: 28px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: var(--error-color-50);
}
.containerChatIcon:hover {
  background-color: var(--error-color);
}
.chatIcon {
  width: 18px;
  height: 18px;
}

.lateralTooltip:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -11px;
  width: 0;
  height: 0;
  transform: rotate(90deg);
  border-top: 8px solid var(--tooltip-color);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.lateralTooltip {
  display: none;
  align-items: center;
  max-width: 320px;
  min-height: 72px;
  background: var(--tooltip-color);
  border-radius: 18px;
  padding: 20px;
  z-index: 5;
}

.student {
  color: var(--bright);
  line-height: var(--font-size-28);
}

.student span {
  font-size: var(--font-size-13);
}
