.tabs {
  display: flex;
}

.tab {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid var(--purpletronic-30);
  border-bottom: none;
  color: var(--dark-grey-30);
  transition: color 0.3s;
}

.tab:last-child {
  margin-right: 0;
}

.tab:hover {
  color: var(--dark-grey-40);
  background-color: var(--seadapted-50);
}

.tab.active {
  color: var(--purpletronic);
  border-bottom: 2px solid var(--seadapted);
}
.tab.disabled {
  cursor: not-allowed;
  background-color: var(--dark-grey-20);
}

.tabContent {
  border: 1px solid var(--dadada);
  padding: 20px;
}
