.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  text-align: center;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  padding: 15px 0;
}
.images {
  width: 267px;
  height: 199px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
}
.images .mainSvg {
  position: absolute;
  margin-right: 20px;
}
.images .backgroundSvg {
  position: absolute;
  margin-top: 5px;
  right: 3px;
}

.sandground {
  color: var(--sandground);
  font-weight: 700;
}
