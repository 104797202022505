.small {
  width: 23px;
  height: 23px;
}
.medium {
  width: 33px;
  height: 35px;
}
.timerSeconds {
  font-size: var(--font-size-10);
  text-anchor: middle;
  font-weight: 500;
}
.timerSecondsStrong {
  font-weight: bold;
}
