.blackboard {
  --cuadernillo-canvas-height: 79vh;
  width: calc(var(--cuadernillo-canvas-height) / 0.9);
  height: var(--cuadernillo-canvas-height);
  margin-inline: auto;
}
.blackboard:focus {
  outline: none;
}
.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  margin-bottom: 6px;
}

.step1,
.step2 {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  gap: 5%;
  padding: 45px;
}
.step3 {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.svg {
  width: 40%;
  height: 30%;
}

.step1 .buttonArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}
.step1 button {
  padding: 0 15px;
}

.canvasArea {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  position: relative;
}
.canvas {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  cursor: crosshair;
}
.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tools .right {
  display: flex;
  gap: 10px;
}
.tools .right .innerRight {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 5px;
  height: 43px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 21.5px;
}
.tools .right .innerRight .icons {
  width: 22px;
  height: 18px;
  margin: 0 8px;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed !important;
}

.step3Empty {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  text-align: center;
  padding: 15px;
}

.imageSync {
  width: inherit;
  height: inherit;
  overflow: auto;
}

.imageSync .day:not(:last-of-type)::after {
  height: 1px;
  display: block;
  background: var(--purpletronic);
  margin: 10px auto;
  content: ' ';
}

.imageSync .title {
  text-transform: uppercase;
  margin-top: 0;
  padding-left: 20px;
  font-weight: 600;
}
.imageSync .content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.imageSync .image {
  cursor: pointer;
  margin: 10px;
  transition: all 0.2s;
  position: relative;
  max-width: 100% !important;
}
.imageSync .image:hover {
  transform: scale(1.05);
}

.imageSync .selected {
  transform: scale(1.1);
  box-shadow: 0px 0px 3px 3px var(--purpletronic);
}

.imageSyncActions {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.zoomImageAdjusted {
  width: calc(100vw - 120px);
  height: auto;
  cursor: zoom-out;
  padding: 8px;
}

.canvasAdjusted {
  width: calc(100vw - 120px);
  height: var(--maxModalContentHeight);
  cursor: zoom-in;
}
