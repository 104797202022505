.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  background: var(--purpletronic);
  box-shadow: 10px 20px 35px 8px rgba(128, 128, 248, 0.05);
  border-radius: 21.5px;
  cursor: pointer;
}
.icon {
  width: 16px;
  height: 18px;
}
.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
