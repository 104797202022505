.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
}

.reconnecting {
  width: 100%;
  height: 78% !important;
}

.emptyContent {
  display: grid;
  place-content: center;
  width: 100%;
  height: 78% !important;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  padding: 15px;
  text-align: center;
}
