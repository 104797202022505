.drawingLineIndicator {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid var(--seadapted);
  border-radius: 50%;
  transform: scale(0);
  animation: clickAnimation 1s infinite;
  margin-left: -10px;
  margin-top: -10px;
}
@keyframes clickAnimation {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.lineAnimation {
  position: absolute;
  border: 1px dashed var(--seadapted);
  transform-origin: 0 0;
}
