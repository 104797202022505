.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  max-width: fit-content;
  max-height: calc(100vh - 195px);
  padding: 2rem 2rem 5px;
  user-select: none;
}

.header {
  align-self: flex-start;
}

.resizableBox {
  position: relative;
  background: white;
  max-width: 100%;
  max-height: 100%;
  width: 650px;
  height: 350px;
  resize: both;
  overflow: hidden;
  box-shadow: 1px 1px 5px 2px rgba(144, 144, 174, 0.2) !important;
  border-radius: 8px;
}
.resizableBox::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 0;
  right: 0;
  background: var(--seadapted-50);
  cursor: se-resize;
  z-index: 10;
}

.canvas {
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}
.toolbar > div {
  box-shadow: 1px 1px 5px 2px rgba(144, 144, 174, 0.2) !important;
}

@media (width < 500px) {
  .modal {
    padding: 0.25rem;
  }
  .resizableBox {
    width: 350px;
    height: 250px;
  }
}
