.container {
  width: 100%;
  height: var(--blackboardCalculatedHeight);
}

.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  margin-bottom: 6px;
}

.bot {
  width: inherit;
  height: inherit;
  border-radius: 16px;
  overflow-y: auto;
}
.centerHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding-top: 2rem;
  margin-top: 0 auto;
}
.svg {
  width: 40%;
  height: 30%;
}
.svgWithContent {
  max-width: 100px;
}

.questionArea {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 2rem 2rem 0rem;
}

.img {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  max-height: 150px;
  min-height: 150px;
  object-fit: contain;
  border: 1px solid var(--purpletronic-40);
  border-radius: 16px;
  background: var(--deepblue);
  cursor: zoom-in;
}
.textArea {
  width: 100%;
}

.markdown {
  position: relative;
  padding: 2rem 3.5rem;
  user-select: text;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
