.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;
  height: 100vh;
  min-width: 500px;
  min-height: 100vh;
  background: var(--deepblue);
  mix-blend-mode: normal;
  position: relative;
  overflow-y: auto;
}

.classfyLogo {
  left: 0;
  top: 0;
  padding-left: 4.5em;
  padding-top: 2.5em;
  position: absolute;
  width: 216px;
  height: 130px;
  z-index: 1;
}

.images {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  width: 470px;
  height: 335px;
}
.images .mainSvg {
  position: absolute;
  margin-right: 20px;
}
.images .backgroundSvg {
  position: absolute;
  margin-top: 13px;
  right: 6px;
}

.googleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  height: 250px;
}

.actions {
  margin-top: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 68px;
  width: 270px;
}

.actions .errorMessage {
  width: fit-content;
  height: 56px;
  background: var(--secondary-error-color);
  border-radius: 98px;
  display: flex;
  align-items: center;
}
.actions .errorMessage .alertSvg {
  width: 43px;
  height: 43px;
  margin: 0 20px;
}
.actions .errorMessage > p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: var(--sandground);
  margin-right: 20px;
}

@media (width < 500px) {
  .main {
    min-width: 250px;
    padding: 0 1rem 1.5rem 1rem;
    gap: 2rem;
  }
  .classfyLogo {
    padding: 1rem;
    position: relative;
    display: flex;
    height: 70px;
  }
  .images {
    width: 100%;
    height: 150px;
  }
  .actions {
    flex-direction: column;
    width: auto;
  }
}
