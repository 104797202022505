.container {
  height: 100%;
  display: grid;
  grid-template-columns: 180px calc(100% - 305px) auto;
  gap: 10px;
  padding: 15px 10px 10px;
  --mainMinClassroomHeight: 700px;
  --blackboardCalculatedHeight: calc(
    max(100vh - 200px, var(--mainMinClassroomHeight) - 200px)
  );
  --mainMinClassroomWidth: 1150px;
  --blackboardCalculatedWidth: calc(max(100%, var(--mainMinClassroomWidth)));
  width: var(--blackboardCalculatedWidth) !important;
}

.containerWitDoubleColumn {
  grid-template-columns: 210px calc(100% - 335px) auto !important;
  gap: 12px;
}

.leftSide {
  min-width: fit-content;
  width: 180px;
  min-height: 88%;
  height: calc(90% - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.classfyLogo {
  width: 130px;
  z-index: 1;
  padding-bottom: 45px;
}
.content {
  width: 100%;
  height: 100%;
  min-height: var(--mainMinClassroomHeight) !important;
}
.content .space {
  width: inherit;
  height: 95%;
  padding-top: 99px;
}
.activeStudent {
  margin-bottom: 10px;
}
.studentList {
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.studentListDoubleColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, minmax(100px, 100px));
}
.rightSide {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.headerProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.headerProfile p {
  width: 100%;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vmin;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.54px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--purpletronic);
}
.headerProfile img {
  width: 33px;
  height: 33px;
  border-radius: 100%;
}
.teacherVideo {
  position: relative;
}
.teacherVideo button {
  width: 220px !important;
}
.reconnectingButton {
  position: absolute;
  top: 50%;
  right: 110%;
  transform: translateY(-50%);
  z-index: 1;
}
.containerChatIcon {
  width: 31px;
  height: 31px;
  padding: 5px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--error-color);
}
.chatMessageAlert {
  background: var(--seadapted);
  height: 10px;
  width: 10px;
  position: absolute;
  top: 0;
  right: -2px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(144, 144, 174, 0.8),
    0 0 20px rgba(144, 144, 174, 0.5);
}
.chatIcon {
  width: 21px;
  height: 21px;
}
.mediaSetup {
  width: 1.8rem !important;
  height: 1.8rem !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  padding: 2px !important;
  background: var(--seadapted);
  animation: none !important;
}
.confirmNavigationModal {
  padding: 10px 5px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.confirmNavigationModal > div {
  display: flex;
  gap: 25px;
}
.chatModalContainer {
  min-width: 240px;
  min-height: 380px;
  max-width: 425px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 8px 8px 8px;
  box-sizing: border-box;
}

.fakeStudentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  padding: 0.25rem;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.fakeStudentButton {
  user-select: none;
  font-size: 1.5rem;
  border-radius: 50%;
  width: 1.25rem;
  height: 1rem;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: inherit;
}

.fakeStudentButton:hover {
  color: var(--purpletronic);
  transform: scale(1.2);
}

.fakeStudentTooltip {
  text-align: center;
}

.fakeStudentIcon {
  width: 1.25rem;
}
