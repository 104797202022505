.blackboard {
  width: 100%;
  height: var(--blackboardCalculatedHeight);
}
.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  margin-bottom: 6px;
}

.step1 {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 5%;
}

.svg {
  width: 40%;
  height: 30%;
}
.h1 {
  text-align: center;
}

.videoSelector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.customSelectContainer {
  width: 400px;
}

.video {
  min-width: inherit;
  width: inherit;
  height: 100%;
  border-radius: inherit;
  cursor: pointer;
}

.tools {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}
.tools .right {
  display: flex;
  gap: 10px;
}
.tools .right {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 5px;
  height: 43px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 21.5px;
}
.tools .right .icons {
  width: 25px;
  height: 20px;
  margin: 0 4px;
  cursor: pointer;
}
