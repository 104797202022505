.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: max(100%, 420px);
  margin: 0 auto;
}

.card {
  width: min(90%, 460px);
  height: fit-content;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 26px;
  margin-top: 40px;
  padding: 20px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerProfileImg {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  border: 5px solid var(--purpletronic);
  border-radius: 50%;
}
.profileImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.pencilIconContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.342);
  background-color: var(--bright);
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 15px;
  cursor: pointer;
  padding: 7px;
}

.personalInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
}

.modalContainer {
  min-width: 240px;
  min-height: 380px;
  max-width: 425px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  box-sizing: border-box;
}

.profileImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileImageWrapper,
.defaultImageWrapper {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  border-radius: 50%;
  border: 2px solid var(--dadada);
  box-shadow: 5px 5px 20px rgba(144, 144, 174, 0.2);
}

.modalActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
