.blackboard {
  width: 100%;
  height: var(--blackboardCalculatedHeight);
  position: relative;
}
.blackboard:focus {
  outline: none;
}

.canvas {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
}
.canvas:active {
  cursor: pointer;
}
.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tools .left {
  display: flex;
  gap: 10px;
}
