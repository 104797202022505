.textarea {
  width: 100%;
  min-width: 100px;
  background: var(--bright);
  border-radius: 10px;
  border: 1px solid var(--purpletronic-40);
  box-sizing: border-box;
  padding: 12px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--shade);
  resize: none;
}
.textarea:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic);
}
.textarea::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}
.disabled {
  border: 1px solid var(--purpletronic-15);
  color: var(--purpletronic-30);
  cursor: not-allowed;
}
.disabled::placeholder {
  color: var(--purpletronic-30);
  opacity: 0.5;
}
.error {
  border: 1px solid var(--sandground);
}
