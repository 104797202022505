.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 25px 25px 10px;
  text-align: center;
}

.paragraph {
  color: var(--purpletronic);
}

.setupSection {
  display: grid;
  gap: 20px;
  text-align: left;
  grid-template-columns: 220px 220px;
  margin-bottom: 15px;
}

.gearIcon {
  width: 55px;
  height: 55px;
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  animation: move 0.5s infinite alternate ease-in-out;
}

.micLabel {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.micLevelMeter {
  width: 40%;
  height: 0.5rem;
  background-color: var(--purpletronic-15);
  border-radius: 10px;
  overflow: hidden;
}

.micLevelBar {
  height: 100%;
  background: var(--deepblue);
  width: 0%;
  transition: width 0.1s ease-out;
  border-radius: 10px 0 0 10px;
}

.radio {
  place-self: center;
  margin-top: 1rem;
}

@keyframes move {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@media (width < 800px) {
  .setupSection {
    gap: 15px;
    grid-template-columns: 1fr;
  }
}
