.links {
  display: inline-flex;
  gap: 25px;
}
.link {
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  color: var(--seadapted);
  cursor: pointer;
}
