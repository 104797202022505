.icon {
  position: fixed;
  top: 2rem;
  right: 0.25rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.networkContainer {
  padding: 20px;
  max-width: 600px;
}

.header {
  font-size: 24px;
  color: var(--dark-grey);
  text-align: center;
  margin-bottom: 20px;
}

.subHeader {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
}

.statusSection {
  margin-bottom: 20px;
}
.statusSection > p {
  margin-bottom: 5px;
}

.status {
  font-size: var(--font-size-18);
  font-weight: bold;
  padding: 15px 0;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
}

.connected {
  color: white;
  background-color: var(--seadapted);
}

.disconnected {
  color: white;
  background-color: var(--error-color);
}

.highlight {
  font-weight: bold;
  color: var(--purpletronic);
  opacity: 0.8;
}
