.hangingUpButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  background: var(--error-color);
  box-shadow: 10px 20px 35px 8px rgba(128, 128, 248, 0.05);
  border-radius: 21.5px;
  position: relative;
  cursor: pointer;
}
.icon {
  width: 33px;
  height: auto;
}

.dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: var(--bright);
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  padding: 5px;
  bottom: 70%;
  z-index: 10;
  width: 200px;
  padding: 15px;
}
.h4Light {
  font-weight: 600;
}
.actions {
  display: flex;
  gap: 10px;
}

.actions button {
  min-width: 10px !important;
  height: 33px !important;
  padding: 3px 20px !important;
}
